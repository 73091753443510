<template>
  <el-card class="box-card">
    <div
        slot="header"
        style="
          display: flex;
          justify-content: space-between;
          padding: 0 50px 0 10px;
        "
        class="clearfix"
    >
      <h3 class="inBlock">中奖名单</h3>
      <back></back>
    </div>
    <div class="container" ref="container">
      <div class="top" style="margin-bottom: 20px">
        <el-form ref="filterForm" v-model="filterForm" inline>
          <el-form-item label="中奖人名称：">
            <el-input v-model="filterForm.username" clearable placeholder="请输入中奖人名称"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="serchTable">搜索</el-button>
          </el-form-item>
        </el-form>
        <el-button type="primary" icon="el-icon-download" @click="exportList">导出</el-button>
      </div>
      <div class="content">
        <el-table
            :data="tableData"
            border
            :max-height="containerHeight - 250"
            stripe
        >
          <template v-for="(item, index) in tableColumn">
            <el-table-column
                v-if="item.prop == 'createtime'"
                :key="'a'+index"
                :width="item.width"
                :fixed="item.fixed"
                :prop="item.prop"
                :label="item.label"
                align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.createtime ? filterTime(scope.row.createtime): '' }}
              </template>
            </el-table-column>
            <el-table-column
                v-else
                :key="'a'+index"
                :prop="item.prop"
                :label="item.label"
                align="center"
            />
          </template>
        </el-table>
      </div>
      <el-pagination
          @current-change="SetPage"
          :current-page="filterForm.page"
          :page-size="filterForm.limit"
          layout="total, prev, pager, next, jumper"
          :total="total"
      >
      </el-pagination>
    </div>
  </el-card>
</template>
<script>
import { getAllBagWinnerList } from "@/api/luckyBag";
import { getSystemsettings } from "@/api/getSystemsetting";
import {filenameFormat} from "@/utils";
export default {
  name: "luckyBagInfo",
  data() {
    return {
      // 容器宽高
      containerHeight: null,
      containerWidth: null,
      // oss前缀
      imgurl: '',
      // 筛选表单
      filterForm: {
        limit: 10,
        page: 1,
        username: '',
        goods_name: '',
        export: '',
        export_type: '',
        blessing_bag_id: ''
      },
      total: 20,
      // 表格数据
      tableData: [],
      // 表头数据
      tableColumn: [
        // {
        //   label: '福袋名称',
        //   prop: 'blessing_bag_title',
        //   width: 250,
        //   fixed: 'left',
        // },
        {
          label: '中奖商品',
          prop: 'goods_name',
          width: 300,
          fixed: 'left',
        },
        {
          label: '中奖时间',
          prop: 'createtime',
          width: 100,
          fixed: false,
        },
        {
          label: '中奖直播间',
          prop: 'live_name',
          width: 100,
          fixed: false,
        },
        {
          label: '中奖人名称',
          prop: 'username',
          width: 200,
          fixed: false,
        },
        {
          label: '中奖人电话',
          prop: 'user_phone',
          width: 200,
          fixed: false,
        }
      ],
    }
  },
  computed: {
    id() {
      return this.$route.query.id
    }
  },
  methods: {
    /**
     * 搜索表格数据
     */
    serchTable() {
      this.filterForm.page = 1
      this.getList()
    },
    /**
     * 分页器
     * @param e
     * @constructor
     */
    SetPage(e) {
      this.filterForm.page = e
      this.getList()
    },
    /**
     * 获取数据列表
     */
    async getList() {
      let { data } = await getAllBagWinnerList(this.filterForm)
      this.total = data.data.total
      this.tableData = data.data.data
    },
    /**
     * 导出中奖名单
     */
    async exportList() {
      this.filterForm.export_type = 1
      this.filterForm.export = 'export'
      let { data, request } = await getAllBagWinnerList(this.filterForm, "blob");
      let blob = new Blob([data], { type: "application/vnd.ms-excel" }); //type是文件类，详情可以参阅blob文件类型
      // let filename = decodeURI(
      //   request.getResponseHeader("content-disposition").split("=")[1]
      // );
      let objectUrl = URL.createObjectURL(blob); //生成下载链接
      let a = document.createElement("a"); //创建a标签用于文件下载
      a.href = objectUrl; //赋值下载路径
      a.download = filenameFormat(
          request.getResponseHeader("content-disposition")
      ); //下载的文件名称（非必填）
      document.body.appendChild(a); //插入DOM树
      a.click(); //点击a标签触发
      document.body.removeChild(a); //删除a标签
      this.filterForm.export_type = ''
      this.filterForm.export = ''
      this.exportShow = false
    },
    /**
     * 格式化时间
     * @param stamp       时间戳
     * @returns {string}  格式化完成的时间字符串
     */
    filterTime(stamp){
      let date = new Date(stamp*1000).toLocaleString('chinese',{hour12:false}).replace(/\//g, "-")
      return date
    }
  },

  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.filterForm.blessing_bag_id = this.id
    getSystemsettings.then((res) => {
      this.imgurl = res;
    });
    this.getList()
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.containerHeight = this.$refs['container'].offsetHeight
    this.containerWidth = this.$refs['container'].offsetWidth
  },
  //生命周期 - 创建之前
  beforeCreate() {
  },
  //生命周期 - 挂载之前
  beforeMount() {
  },
  //生命周期 - 更新之前
  beforeUpdate() {
  },
  //生命周期 - 更新之后
  updated() {
  },
  //生命周期 - 销毁之前
  beforeDestroy() {
  },
  //生命周期 - 销毁完成
  destroyed() {
  },
  //如果页面有keep-alive缓存功能，这个函数会触发
  activated() {
  },
}
</script>
<style scoped lang="less">
/deep/.el-card__body {
  height: 100%;
}
.container {
  padding: 20px;
  height: 100%;
  .option {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
}
</style>
